import React from "react";
import LeadForm from "../Forms/LeadForm";

import Image from "../image";
import data from "../../data/forms/lead-form";

export default function DemoPage({ formId, thankYouPath, leadType }) {
  return (
    <section className="demo" style={{ marginTop: 0, paddingTop: 0 }}>
      <div className="demo__content-wrapper">
        <div className="form-wrapper">
          <LeadForm
            formId={formId}
            leadType={leadType}
            dataLayerEventNameAppendix="-lead"
            formName="Lead Form"
            formData={data}
            thankYouPath={thankYouPath}
            header="Get your free demo"
            subHeader="Learn how SpotOn products can help you run and grow your business."
            formItemsToSend={[
              "firstname",
              "email",
              "phone",
              "company",
              "text_messages",
            ]}
            lineOfBusiness="Hospitality"
          />
        </div>
        <Image
          imageName="demo_image.png"
          className="demo__heading-img"
          style={{ height: "100%" }}
        />
      </div>
    </section>
  );
}
