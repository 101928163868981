import React from "react";
import { PropTypes } from "prop-types";
import Carousel from "nuka-carousel";
import { Row, Col, Typography } from "antd";
import LocalSliderCard from "./LocalSliderCard";
import chevron from "../../images/svg/chevron_left.svg";

const { Title } = Typography;

const LocalSlider = (props) => {
  const { testimonialsData, nav, title } = props;
  return (
    <div className="local-slider" id={nav}>
      <Row className="pos-section__text" style={{ marginBottom: 40 }}>
        <Col span={24}>
          <Title level={2}>
            {" "}
            {title || "What other businesses have to say"}
            <span className="blue_dot">.</span>{" "}
          </Title>
        </Col>
      </Row>
      <Carousel
        dragging
        slidesToShow={1}
        enableKeyboardControls
        renderCenterLeftControls={({ previousSlide }) => (
          <button type="button" className="btn-left" onClick={previousSlide}>
            <img
              src={chevron}
              alt="chevron icon"
              style={{ transform: "rotate(180deg)" }}
            />
          </button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <button type="button" className="btn-right" onClick={nextSlide}>
            <img src={chevron} alt="chevron icon" />
          </button>
        )}
        renderBottomCenterControls={null}
        cellSpacing={30}
      >
        {testimonialsData &&
          testimonialsData.map((item, idx) => (
            <LocalSliderCard
              key={idx.toString()}
              testimonial={item.testimonial}
              client={item.client}
              company={item.company}
              imageOne={item.imageOne}
              imageTwo={item.imageTwo}
              imageThree={item.imageThree}
            />
          ))}
      </Carousel>

      <style jsx global>{`
        .local-slider {
          margin-top: 100px;
          margin-bottom: 100px;
          padding: 0 126px;
        }
        .local-slider h2 {
          text-align: center;
        }
        @media screen and (max-width: 768px) {
          .local-slider {
            padding: 0 14px;
          }
        }
        .local-slider h2 {
          font-weight: 800;
        }
        @media (min-width: 768px) and (max-width: 1260px) {
          .local-slider h2 {
            font-size: 36px !important;
          }
        }
        .local-slider .grid-2 {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 16px;
        }
        @media (max-width: 600px) {
          .local-slider .grid-2 {
            grid-template-columns: 1fr;
          }
          .local-slider {
            margin-bottom: 0px;
          }
        }
        .testimonial-carosel {
          width: 100%;
          box-sizing: border-box;
        }
        .testimonial-carosel--car {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 16px;
        }
        @media (max-width: 600px) {
          .testimonial-carosel--car {
            grid-template-columns: 1fr;
            display: flex;
            flex-direction: column;
          }
        }
        .testimonial-carosel--car img {
          border-radius: 8px;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
        .testimonial-carosel--car__imgs {
          height: 220px !important;
        }
        .testimonial-carosel--car__imgs img {
          object-fit: cover;
          object-position: center;
          height: 220px !important;
          width: 100%;
        }
        .testimonial-carosel--car__text {
          min-height: 50%;
          background-color: #161920;
          color: #fff;
          font-size: 24px;
          padding: 11%;
          border-radius: 8px;
          margin-bottom: 16px;
          font-weight: 700;
          text-align: center;
        }
      `}</style>
    </div>
  );
};

LocalSlider.propTypes = {
  nav: PropTypes.string,
  title: PropTypes.string,
  testimonialsData: PropTypes.PropTypes.arrayOf(PropTypes.any),
};
LocalSlider.defaultProps = {
  nav: "",
  title: "",
  testimonialsData: [],
};

export default LocalSlider;
