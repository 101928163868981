import React from "react";
import { Row, Col } from "antd";
import Image from "../ImageQuerys/LocalImages";

const PartnerLogos = () => {
  return (
    <Row
      align="middle"
      gutter={20}
      style={{ marginTop: 70, marginBottom: 70, margin: "auto" }}
    >
      <Col xs={8} md={4}>
        <Image
          imageName="miss_goggi.jpg"
          style={{
            maxWidth: 200,
            maxHeight: 200,
            zIndex: 2,
            margin: "auto",
          }}
          className="local-logos"
        />
      </Col>
      <Col xs={8} md={4}>
        <Image
          imageName="Big_citizen.jpg"
          style={{ maxWidth: 200, maxHeight: 200, margin: "auto" }}
          className="local-logos"
        />
      </Col>
      <Col xs={8} md={4}>
        <Image
          imageName="Hacienda.png"
          style={{ maxWidth: 200, maxHeight: 200, margin: "auto" }}
          className="local-logos"
        />
      </Col>
      <Col xs={8} md={4}>
        <Image
          imageName="HM_logo_bw.png"
          style={{ maxWidth: 150, maxHeight: 150 }}
          className="local-logos-large"
        />
      </Col>
      <Col xs={8} md={4}>
        <Image
          imageName="rileys.png"
          style={{ maxWidth: 200, maxHeight: 200, zIndex: 2 }}
          className="local-logos-large"
        />
      </Col>
      <Col xs={8} md={4}>
        <Image
          imageName="el_don.png"
          style={{ maxWidth: 150, maxHeight: 150 }}
          className="local-logos-large"
        />
      </Col>
    </Row>
  );
};

export default PartnerLogos;
