import React from "react";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import DemoPage from "../../../components/ColoradoPortedComps/DemoPage";
import LocalSlider from "../../../components/ColoradoPortedComps/LocalSlider";
import PartnerLogos from "../../../components/ColoradoPortedComps/PartnerLogos";
import { georgiaTestimonialsData } from "../../../data/local/georgia-data";

import ogImage from "../../../images/global_assets/og-image.png";

const Demo = () => {
  return (
    <Layout phoneNumber="+16784368087" phoneText="(678) 436-8087">
      <SEO
        title="SpotOn Georgia| Restaurant Point Of Sale"
        description="SpotOn Georgia makes it easier than ever for on-the-go food businesses like food trucks to take orders, print tickets, process payments, and stay online just about anywhere."
        image={`https://spoton.com/${ogImage}`}
      />
      <DemoPage
        formId="8f098f70-e3cc-48ce-a53e-79e6ade078da"
        thankYouPath="/local/georgia/thank-you"
      />
      <br />
      <br />
      <br />
      <br />
      <PartnerLogos />
      <LocalSlider testimonialsData={georgiaTestimonialsData} />
    </Layout>
  );
};

export default Demo;
