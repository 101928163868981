import React from 'react';
import { PropTypes } from 'prop-types';
import Image from '../ImageQuerys/LocalImages';

const LocalSliderCard = (props) => {
  const {
    testimonial,
    client,
    company,
    imageOne,
    imageTwo,
    imageThree,
  } = props;
  return (
    <div className="testimonial-carosel--car">
      <Image imageName={imageOne} />
      <div>
        <div className="testimonial-carosel--car__text">
          <p>
            {testimonial} <br />
            <br />
            <span style={{ fontStyle: 'italic' }}>{client}</span>
            <br />
            <span
              style={{ fontStyle: 'italic', fontSize: 20, fontWeight: 200 }}
            >
              {company}
            </span>
          </p>
        </div>
        <div className="grid-2 testimonial-carosel--car__imgs">
          <Image imageName={imageTwo} />
          <Image imageName={imageThree} />
        </div>
      </div>
    </div>
  );
};

LocalSliderCard.propTypes = {
  testimonial: PropTypes.string,
  client: PropTypes.string,
  company: PropTypes.string,
  imageOne: PropTypes.string,
  imageTwo: PropTypes.string,
  imageThree: PropTypes.string,
};
LocalSliderCard.defaultProps = {
  testimonial: '',
  client: '',
  company: '',
  imageOne: '',
  imageTwo: '',
  imageThree: '',
};

export default LocalSliderCard;
